.no-padding{
padding: 0 !important;
}
p, h1,h2,h3,h4,h5,h6,li,ul,span,div{
font-family: "Playfair Display", Georgia, serif;
}
@font-face {
    font-family: 'sans';
    src: url('../fonts/IRANSans/IRANSansWeb_Light.woff') format('woff'),
    url('../fonts/IRANSans/IRANSansWeb_Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
}


.column {
    float: left;
    width: 33.33333333%;
    padding: 10px;
}

.column img {
    margin-top: 12px;
    width: 100%;
}

/* Clear floats after the columns */
.rowGallery:after {
    content: "";
    display: table;
}




.fontFamily li a{
    font-family: sans !important;
}



body{
    background-color: #1f3439 !important;
}
body, html {
    height: 100%;
    margin: 0 !important;
}
.logoNav{
    float: right;
    position: relative;
    top: 5px;
    margin-right: 20px;
}
.logoNavFa{
    float: left;
    position: relative;
    top: 5px;
    margin-left: 20px;
}

.background{
    height: 500px;
    background-attachment: fixed;
    background-position: bottom ;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:  url("../images/backgrund.jpg")  ;
}

.navbar{
    background-color: rgba(128,99,66,0.4);
    box-shadow: 0 0 20px 0px #333;
    position: absolute !important;
    top: 0;
    width: 100%;
    border-radius: 0 !important;
}
.navbar li{
    color: #000;
}
.navbar a{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-right: 18px;
}

.galleryPhoto p{
    color: #fff;
    padding-left: 7px;
    margin: 0;
    background-color: #806342;
    padding-top: 8px;
    padding-bottom: 5px;
    font-family: sans;
}
.galleryPhoto img {
    cursor: pointer;
}
.galleryPhotoFa img {
    cursor: pointer;
}
.galleryPhotoFa p{
    color: #fff;
    padding-right: 7px;
    font-family: sans !important;
    margin: 0;
    text-align: right;
    background-color: #806342;
    padding-top: 8px;
    padding-bottom: 5px;
}

.sheidaKeyhan{
    margin-top: 10%;
    width: 85%;
}


.Services{
   position: relative;
    padding: 10em 0;
    clear: both;
    margin-top: 20%;
}
.portfolio{
    background-color: #806342 !important;
    height: 200px !important;
    padding: 10px !important;
}
.portfolio h3{
    color: #fff !important;
    font-size: 200px !important;
}

.Works{
   position: relative;
    padding: 10em 0 0;
    clear: both;
    margin-top: 42%;
}
.boldTitle{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 250px;
    color: #f0f0f0;
    font-weight: 700;
}
.ImageSize{
    width: 84%;
    margin-top: 20%;
    margin-left: 0;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.FirstName{
    font-size: 50px;
    margin-bottom: 0;
    margin-top: 23%;
}

.LastName{
    font-size: 50px;
    margin-bottom: 0;
}

.heading{
    font-family: "Karla", Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1em;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left top 0;
    -ms-transform-origin: left top 0;
    transform-origin: left top 0;
    position: relative;
    margin-top: 360%;
    color: #fff;
}
.heading:after{
    position: absolute;
    top: 8px;
    right: -145px;
    content: '';
    background: #fff;
    width: 40px;
    height: 1px;
}
.headingContact{
    font-family: "Karla", Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1em;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left top 0;
    -ms-transform-origin: left top 0;
    transform-origin: left top 0;
    position: relative;
    margin-top: 115%;
    color: #fff;
}
.headingContact:after{
    position: absolute;
    top: 8px;
    right: -145px;
    content: '';
    background: #fff;
    width: 40px;
    height: 1px;
}
.textAbout{
    color: #FFFFFF;
    font-size: 15px;
    margin-top: 20px;
    line-height: 30px;
    font-family: Karla,Arial,sans-serif !important;
    text-align: justify;
}
.textAboutFa{
    color: #FFFFFF;
    font-size: 15px;
    margin-top: 20px;
    line-height: 40px;
    font-family: sans !important;
    text-align: justify;
    float: right;
    direction: rtl;
}
.titlePage{
    color: #fff;
    width: 62%;
    line-height: 60px;
    margin-top: 40px;

}
.titlePageFa{
    color: #fff;
    width: 62%;
    line-height: 60px;
    margin-top: 40px;
    text-align: right;
    font-family: sans !important;
    float: right;

}

.aboutY{
    position: relative;
    right: -55px;
}
.roteName{
    font-family: "Karla", Arial, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1em;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left top 0;
    -ms-transform-origin: left top 0;
    transform-origin: left top 0;
    position: relative;
    margin-top: 50px;
    right: 30px;
    top: 45px;

}
.roteName:after{
    position: absolute;
    top: 8px;

    right: -158px;
    content: '';
    background: #000;
    width: 40px;
    height: 1px;
}

.marginTopAbout{
    margin-top: 45px;
    margin-bottom: 1.5em;
    font-weight: 400;
    font-family: "Karla", Arial, sans-serif;
    font-size: 19px;
}

.socialsIcon i{
    margin-right: 30px;
    font-size: 18px;
}
.socialsIcon a{
    color: #fff;
}

.socialsIcon{
    margin-top: 40px;
}
.socialsIconFa i{
    margin-left: 30px;
    font-size: 18px;
}
.socialsIconFa a{
    color: #fff;
}

.socialsIconFa{
    margin-top: 40px;
    float: right;
}

.btnPersonal{
    background-color: transparent;
    border-radius: 0 !important;
    display: block !important;
    margin: 60px auto 0;
    color: #fff;
    padding: 10px !important;
    border: 1px solid #eee !important;
    font-family: "Karla", Arial, sans-serif;
    width: 280px;
}
.btnPersonal:hover{
    background-color: transparent;
    border-radius: 0 !important;
    display: block !important;
    margin: 60px auto 0;
    color: #fff !important;
    padding: 10px !important;
    border: 1px solid #806342 !important;
    transition: all 400ms;
}

.titleName{
    display: block;
    margin-top: 90px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
    margin-bottom: 10px;
    color: #bfbfbf;
    font-weight: 600;
    font-family: "Karla", Arial, sans-serif;
}
.TopAbout{
    top:60px;

}

.titleProps{
    font-size: 34px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1.5em;
    color: #000;
    margin-top: 25px;
    position: relative
}

.titleWork{
    display: block;
    text-align: center;
    margin-top: 90px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
    margin-bottom: 10px;
    color: #bfbfbf;
    font-weight: 600;
    font-family: "Karla", Arial, sans-serif;
}

.titlePropsWork{
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1.5em;
    color: #000;
    margin-top: 25px;
    position: relative
}
.titlePortfolio{
    display: block;
    text-align: center;
    margin-top: 220px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
    margin-bottom: 10px;
    color: #bfbfbf;
    font-weight: 600;
    font-family: "Karla", Arial, sans-serif;
}

.titlePropsPortfolio{
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1.5em;
    color: #000;
    margin-top: 25px;
    position: relative
}

.opacityWork{
    opacity: 0.7;
}

.ImageServices{
    width: 82%;
    margin-top: 52px;
    float: right;
}

.FrontText{
    display: block;
    font-size: 15px;
    letter-spacing: 3px;
    margin-bottom: 50px;
    text-align: center;
    color: #000;
    font-weight: 600;
    font-family: "Karla", Arial, sans-serif;
}

.ListSkills li{
    list-style: none;
    margin-bottom: 10px;
    font-family: "Karla", Arial, sans-serif;
}
.ListSkills li:before{
    content: '\2022';
    color: #000;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.ColorList{
    border-left: 1px solid #eee;
}

.TitleColorCol{
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: "Karla", Arial, sans-serif;
    font-size: 19px;
    color: #000;
}
.TitleColorDes{
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: "Karla", Arial, sans-serif;
    font-size: 15px;
    color: #666;
}

.yearWorks{
    font-size: 50px;
}
.monthAll{
    font-family: "Karla", Arial, sans-serif;
    font-size: 14px;
    position: relative;
    top: 20px;
    color: #bfbfbf;
}
.WorkName{
    text-align: right;
    font-family: "Karla", Arial, sans-serif;
}
.colorSpan{
    color: #1abc9c;
}
.companyName{
    color: #1abc9c;
    font-size: 24px;
}
.SizeFont{
    color: #bfbfbf;
    font-size: 12px;
}
.ListWork{
    margin-bottom: 285px;
}

.cardPro img{
    width: 100%;
    height: 205px;
}
.cardPro img:hover{
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    transition: all 400ms;
    box-shadow: 4px 4px 8px 0 rgba(0,0,0,0.2);
}
.cardPro {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin-bottom: 10px;
    margin-top: 10px;
}

.Technology{
    font-family: "Karla", Arial, sans-serif;
}

.cardBlog img{
   width: 100%;
}
.sliderDesign p{
    text-align: center !important;
    font-family: sans !important;
    direction: rtl;
    padding:0 10px 0 10px;
    line-height: 23px;
    color: #fff;
}
.sliderDesign2 p{
    text-align: center !important;
    font-family: sans !important;
    direction: rtl;
    padding:0 10px 0 10px;
    line-height: 23px;
    color: #fff;
}
.sliderDesign {
    background-color: #806342;
    height: 555px;
}
.sliderDesign2 {
    background-color: #806342;
    height: 260px;
}

.GalleryImage{
    color: #fff;
    text-align: center;
    margin-top: 10%;
    font-size: 85px;
    margin-bottom: 45px;
}
.GalleryImageG{
    color: #fff;
    text-align: center;
    margin-top: 3%;
    font-size: 85px;
    margin-bottom: 45px;
}
.GalleryImageGF{
    color: #fff;
    font-family: sans !important;
    text-align: center;
    margin-top: 3%;
    font-size: 85px;
    margin-bottom: 45px;
}
.GalleryImageFa{
    color: #fff;
    text-align: center;
    margin-top: 10%;
    font-size: 85px;
    font-family: sans !important;
    margin-bottom: 45px;
}

.sliderDesign h3{
   text-align: center !important;
    font-family: sans !important;
    font-size: 16px;
    font-weight: bold;
    padding:0 10px 0 10px;
    color: #fff;
}
.sliderDesign2 h3{
   text-align: center !important;
    font-family: sans !important;
    font-size: 16px;
    font-weight: bold;
    padding:0 10px 0 10px;
    color: #fff;
}

.photoGallery{
    color: #eee;
    text-align: center;
    font-size: 31px;
    position: relative;
    margin-top: -60px;
    font-family: "Karla", Arial, sans-serif;
}
.footerColor{
    margin-top: 100px;
    height: 100px;
    background-color: #806342;
    float: right;
    width:100%;
}

.contactUsPage{
    font-family: "Karla", Arial, sans-serif;
    color: #fff;
    margin-top: 20px;

}

.contactUsPageFa{
    font-family: sans !important;
    text-align: right !important;
    direction: rtl;
    color: #fff;
    margin-top: 20px;

}

.contactUs{
    color: #fff;
    font-size: 18px;
    margin-bottom: 50px;
}
.contactUsFa{
    color: #fff;
    font-size: 18px;
    font-family: sans !important;
    text-align: right !important;
    margin-bottom: 50px;
}

.imageContact{
    width: 60%;
    margin: 0 auto;
    display: block;
}

.sliderDesign img{
    width: 75%;
    padding-top: 40px;
    height: auto;
    margin: 0 auto;
    display: block;
}
.sliderDesign2 img{
    width: 75%;
    padding-top: 40px;
    height: auto;
    margin: 0 auto;
    display: block;
}

.colorTag{
    color: #222;
    padding: 10px;
}
a:hover{
    text-decoration: none !important;
}
.socialsIconFooter{
    justify-content: center;
    display: flex;
    margin-top: 31px;
}
.socialsIconFooter a{
    border: 1px solid #222;
    padding: 10px;
    border-radius: 100%;
    margin-right: 10px;
}
.socialsIconFooter a:hover{
  background-color: #aaa;
    transition: all 400ms;
    border: 1px solid #aaa;
}
.socialsIconFooter a:hover i{
 color: #fff;
}
.socialsIconFooter i{
    color: #000;
    font-size: 20px;
}

.FooterText{
    text-align: center;
    margin-top: 36px;
    color: #fff;
    font-size: 20px;
}

.LeftMenu{
    float: right;
    margin-top: 14px;
    font-size: 22px !important;
    margin-right: 15px;
}

.heightDefult{
    min-height: 650px;
}
.TOPBLAG{
    top: 75px !important;
}
.HeightBlog{
    height: 355px;
}
.ImageLoading{
    margin: 0 auto;
    display: block;
    width: 20%;
}

.navFaRe{
    float: right !important;
}

@media (max-width: 460px) {

    .image_1swebtw-o_O-imageLoaded_zgbg08{
        height: 100vh!important;
        max-height: none !important;
        min-height: auto !important;
    }


    .GalleryImageFa{
        font-size: 45px;
    }
    .btn-Design{
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }
    .heading{
        display: none;
    }
    .titlePage{
        width: 75%;
    }
    .slick-next{
        display: none !important;
    }
    .slick-prev{
        display: none !important;
    }
    .headingContact{
        display: none;
    }
    .navbar-nav{
        justify-content: center;
        display: flex;
        margin: 0 !important;
    }
    .logoNav {
         float: none;
        margin: 0 auto;
        display: block;
    }
    .column {
        width: 100%;
    }
    .logoNavFa {
        float: none;
        margin: 0 auto;
        display: block;
    }
    .aboutY{
        display: none;
    }
.navFaRe{
float: none !important;
}
    .GalleryImage{
        font-size: 55px;
    }
    .background{
        background-attachment: unset;
    }
}


.form-group label{
    color: #fff;
}
.form-group input{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    box-shadow: none;
    color: #fff;
}
.form-group textarea{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    box-shadow: none;
    margin-top: 20px;
    color: #fff;
}

.margin-top-20{
    margin-top: 20px;
}

.form-control:focus {
    border-color: #806342 !important;
    outline: 0 !important;
    box-shadow: inset 0 1px 1px rgba(128,99,66,.075), 0 0 8px rgba(128,99,66,.6) !important;
}

.btn-Design{
    margin: 0 auto ;
    display: block !important;
    margin-top: 246px ;
    background: transparent !important;
    border: 1px solid #fff !important;
    border-radius: inherit !important;
    color: #fff !important;
    font-family: sans;
}



.formFaLabel{
    font-family: sans !important;
    text-align: right;
    direction: rtl;
}

.SuccessForm{
    color: #fff;
    background-color: #806342;
    padding: 10px;
    font-family: sans !important;
    float: left;
    width: 100%;
    border-radius: 9px;
}
.SuccessFormّfa{
    color: #fff;
    background-color: #806342;
    padding: 10px;
    font-family: sans !important;
    float: left;
    width: 100%;
    border-radius: 9px;
    text-align: right;
}


.galleryPhotoFa p:last-child {
    direction: rtl;
}
